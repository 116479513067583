.title {
	border-bottom: 1px solid #ccc;
	font-weight: 600;
	padding-bottom: 10px;

	&.no-border {
		border-bottom: 0;
		padding-bottom: 0;
	}
}

.property-item {
	background: white;
	border: 1px solid #ccc;
	padding: 10px 10px;
	margin-bottom: 10px;
}

.herds {
	clear: both;
	margin: 0 25px;
	.herd-item {
		padding: 5px;
		border-bottom: 1px solid #f0f0f0;

		&:last-child {
			border-bottom: 0;
			padding-bottom: 0;
		}
	}

	.bp3-icon-add {
		position: relative;
		top: 5px;
	}
}
