.App {
	font-size: 12px;
}

body {
	background: rgba(164, 200, 225, 0.09);
	font-size: 0.8em;
}

.page {
	max-width: 600px;
	margin: 0 auto;
}
ul,
li {
	list-style: none;
	margin: 0;
	padding: 0;
}

a {
	color: #563a1d;
	&:hover {
		color: #a76e3a;
	}
}

hr {
	border-color: #cccccc2b;
	margin: 10px;
}

table {
	width: 100%;
	th {
		font-weight: bold;
		font-size: 1em;
		text-align: center;
	}

	td {
		text-align: center;
	}

	&.left-align {
		th {
			text-align: left;
		}

		td {
			text-align: left;
		}
	}
}

h1 {
	display: flex;
	justify-content: space-between;
	margin: 20px 10px;
}

input,
select,
textarea {
	border: 1px solid #ccc;
	padding: 5px 5px;

	&:invalid {
		background-color: rgba(122, 9, 23, 0.08);
		color: #7a0917;
	}

	&::-webkit-input-placeholder,
	&::-moz-placeholder,
	&:-ms-input-placeholder,
	&:-moz-placeholder {
		/* Chrome/Opera/Safari */
		color: #cecdcd;
		font-size: 10px;
	}
}

.bp3-form-group label.bp3-label {
	margin-bottom: 3px;
	margin-top: 5px;
}

.bp3-form-group.bp3-inline {
	padding-bottom: 10px;
}
.bp3-form-group.bp3-inline label.bp3-label {
	line-height: 25px !important;
	width: 100px;
}

.bp3-select {
	select {
		height: 25px !important;
		top: -2px;
		position: relative;
	}

	&::after {
		top: 3px !important;
	}
}

.nav-links {
	padding: 20px;

	li {
		padding: 3px 0;
	}
}

.App-header {
	background-color: #563a1d;
	height: 50px;
	padding: 20px 20px 20px 0;
	color: #efefef;
	padding-top: 16px;

	//position: fixed;
	//top: 0;
	//width: 100%;

	h1 {
		margin: 0;
		a {
			color: #c7c5c2;
			margin: -2px 10px 0px 10px;
		}
	}

	.App-logo {
		height: 30px;
		float: left;
	}

	.App-title {
		font-size: 1.5em;
		float: left;
		padding-left: 10px;
		color: #eae8e5;
	}

	.App-menu {
		float: right;

		.app-tag {
			position: relative;
			top: -13px;
			right: 20px;
		}
	}
}

.page {
	padding: 10px 10px;
	width: 100%;
	padding-bottom: 20px;
	height: 100%;
	overflow: auto;
	overflow-x: hidden;

	.bp3-card {
		margin: 10px 0;
		cursor: pointer;
		width: 100%;
		padding: 15px 10px;
	}

	h4 {
		margin: 5px 0;
		font-size: 1.3em;
		clear: both;
		display: flex;

		&.page-title {
			justify-content: center;
		}
	}
}

.bp3-form-group {
	margin-bottom: 0;

	.treatment {
		&.bp3-inline {
			width: 100%;
			margin-bottom: 5px;

			.bp3-label {
				min-width: 60px;
				width: 20%;
			}

			.bp3-form-content {
				width: 80%;
			}
		}
	}
	&:not(.bp3-inline) {
		display: inline-block !important;
		padding: 0 5px;

		&:first-child {
			padding-left: 0;
		}

		&:last-child {
			padding-right: 0;
		}
	}

	&.fill {
		margin-bottom: 10px;
	}
}
.flex {
	display: flex;
}

.bp3-button-text {
	line-height: 0;
}

.right {
	float: right;
}

.left {
	float: left;
}

.bp3-html-select {
	width: 100%;
}

.fill {
	width: 100%;
	.bp3-popover-target {
		width: 100%;
	}
}

.bp3-transition-container {
	background: white;
}

.bp3-popover {
	.bp3-form-group {
		margin-bottom: 10px;
		.bp3-label {
			width: 80px;
		}

		.bp3-form-content {
			width: calc(100% - 80px);
			input,
			.bp3-html-select {
				width: 100%;
			}
		}
	}
}

.buttons {
	.bp3-popover-target {
		width: 100%;
	}
}

.bp3-slider {
	min-width: 80px;
	.bp3-slider-handle .bp3-slider-label {
		background: none;
		color: black;
	}
}
